




















































import { Component, Vue } from "vue-property-decorator";

import Calendar from "@/common/components/Calendar/Calendar.vue";
import AvailabilityDialog from "@/common/components/Dialogs/AvailabilityDialog.vue";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import moment, { Moment } from "moment";
import axios from "@/libs/axios/axios";
import { AxiosResponse, AxiosError } from "axios";
import { State } from "vuex-class";
import { UserState } from "@/common/store/user/types";
import { find, findIndex } from "lodash";

@Component({
  components: {
    Calendar,
    AvailabilityDialog
  }
})
export default class Availability extends Vue {
  @State("user") user!: UserState;
  period!: {
    eventType: string;
    color: string;
    days: {
      day: Moment;
      dayParts: {
        morning: boolean;
        afternoon: boolean;
      };
    }[];
  }[];

  days: {
    day: Moment;
    dayParts: {
      morning?: number | boolean;
      afternoon?: number | boolean;
    };
  }[] = [];

  today: Moment = moment();
  currentMonth: Moment = moment().startOf("month");
  editingDay = {
    day: moment(),
    dayParts: {
      morning: {
        value: false,
        disabled: false,
        substitionId: null
      },
      afternoon: {
        value: false,
        disabled: false,
        substitionId: null
      },
      successive: {
        value: false,
        disabled: false
      }
    }
  };
  dialogVisible = false;

  async mounted() {
    this.getAvailability();
  }

  async getAvailability() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    this.days = [];
    if (this.user.currentTenant && this.user.currentTenant.id) {
      const response: void | AxiosResponse = await axios
        .get("availability", {
          headers: {
            "X-VVTOOL-TENANT": this.user.currentTenant.id
          },
          params: {
            start: this.currentMonth.format("YYYY-MM-DD"),
            end: this.currentMonth
              .clone()
              .endOf("month")
              .format("YYYY-MM-DD")
          }
        })
        .catch((error: AxiosError) => {
          console.log(error);
        });

      if (response && response.data) {
        response.data.availability.forEach(function(availabilityDay: {
          date: Moment;
          periodOfDay: number;
          plannedSubstitution: null | {
            id: number;
            status: string;
          };
        }) {
          const found = self.findDay(availabilityDay.date, findIndex);
          const daypart =
            availabilityDay.periodOfDay === 1 ? "morning" : "afternoon";
          if (found >= 0) {
            self.days[found].dayParts[daypart] =
              availabilityDay.plannedSubstitution !== null
                ? availabilityDay.plannedSubstitution.id
                : true;
          } else {
            self.days.push({
              day: moment(availabilityDay.date),
              dayParts: {
                [daypart]:
                  availabilityDay.plannedSubstitution !== null
                    ? availabilityDay.plannedSubstitution.id
                    : true
              }
            });
          }
        });
      }
    }
  }

  get daysWithEvents() {
    return [
      {
        eventType: "single-event",
        color: "green",
        days: this.days.map(day => {
          return {
            ...day,
            dayParts: {
              morning: day.dayParts.morning === true,
              afternoon: day.dayParts.afternoon === true
            }
          };
        })
      },
      {
        eventType: "single-event",
        color: "lightblue",
        days: this.days.map(day => {
          return {
            ...day,
            dayParts: {
              morning: typeof day.dayParts.morning === "number",
              afternoon: typeof day.dayParts.afternoon === "number"
            }
          };
        })
      }
    ];
  }

  findDay(search: Moment, method: Function = find) {
    return method(this.days, function(day: {
      day: Moment;
      dayParts: {
        morning: {
          value: boolean;
          disabled: boolean;
          substitionId: null | number;
        };
        afternoon: {
          value: boolean;
          disabled: boolean;
          substitionId: null | number;
        };
      };
    }) {
      return day.day.isSame(search, "day");
    });
  }

  setAvailability(value: { dayPart: string; value: boolean }) {
    this.editingDay = {
      ...this.editingDay,
      dayParts: {
        ...this.editingDay.dayParts,
        [value.dayPart]: { value: value.value, disabled: false }
      }
    };
  }

  async saveDayParts() {
    if (this.user.currentTenant && this.user.currentTenant.id) {
      const availabilityRecords = [];

      for (
        let day = this.editingDay.day.clone();
        day <=
        (this.editingDay.dayParts.successive.value === true
          ? this.editingDay.day.clone().endOf("month")
          : this.editingDay.day);
        day.add("7", "days")
      ) {
        const foundDay = this.findDay(day);

        availabilityRecords.push(
          ...(!foundDay || typeof foundDay.dayParts.morning !== "number"
            ? [
                {
                  date: day.format("YYYY-MM-DD"),
                  period: 1,
                  available: this.editingDay.dayParts.morning.value
                }
              ]
            : []),
          ...(!foundDay || typeof foundDay.dayParts.morning !== "number"
            ? [
                {
                  date: day.format("YYYY-MM-DD"),
                  period: 2,
                  available: this.editingDay.dayParts.afternoon.value
                }
              ]
            : [])
        );
      }
      await axios
        .put("availability", availabilityRecords, {
          headers: {
            "X-VVTOOL-TENANT": this.user.currentTenant.id
          }
        })
        .catch((error: AxiosError) => {
          console.log(error);
        });

      this.getAvailability();

      this.dialogVisible = false;
    }
  }

  selectDate(selectedDay: Moment) {
    const found = this.findDay(selectedDay);

    const successiveDisabled =
      selectedDay >
      selectedDay
        .clone()
        .endOf("month")
        .subtract("7", "days");
    if (found) {
      this.editingDay = {
        day: selectedDay,
        dayParts: {
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          //@ts-ignore
          morning: {
            value: found.dayParts.morning === true,
            disabled: typeof found.dayParts.morning === "number",
            substitionId:
              typeof found.dayParts.morning === "number"
                ? found.dayParts.morning
                : null
          },
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          //@ts-ignore
          afternoon: {
            value: found.dayParts.afternoon === true,
            disabled: typeof found.dayParts.afternoon === "number",
            substitionId:
              typeof found.dayParts.afternoon === "number"
                ? found.dayParts.afternoon
                : null
          },
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          //@ts-ignore
          successive: { value: false, disabled: successiveDisabled }
        }
      };
    } else {
      this.editingDay = {
        day: selectedDay,
        dayParts: {
          morning: { value: false, disabled: false, substitionId: null },
          afternoon: { value: false, disabled: false, substitionId: null },
          successive: { value: false, disabled: successiveDisabled }
        }
      };
    }
    this.dialogVisible = true;
  }

  previousMonth() {
    this.currentMonth = this.currentMonth.clone().subtract(1, "M");
    this.getAvailability();
  }

  nextMonth() {
    this.currentMonth = this.currentMonth.clone().add(1, "M");
    this.getAvailability();
  }
}
